import React from 'react'

import StoryComponenet from '../components/Story/story'

const Story = (props) => (
  <div>
    <StoryComponenet { ...props }/>
  </div>
)

export default Story
