import React from 'react'

import emma from '../../assets/emma.jpg';
import Container from '../../components/Container/container';
import './story.scss'

const Story = () => (
    <div className="story">
        <Container>
            <h2>The Story Behind Tearribles</h2>
            <h4>( And a dog named Emma )</h4>
            <div className="story__wrapper">
                <div className="story__images">
                    <img className="story__img" src={emma} alt="Four daschunds who's descrutive nature inspired the idea for a tear apart dog toy." />
                </div>
                <div className="story__text">
                    <p>I've always been a dog lover. I'm that person who goes to a party and makes friends with the dog. Needless to say, I have spent my entire life around dogs and I am very grateful for that. I'm not going to bore you with details of my life- instead I will tell you about one moment in it that "knocked over the first Domino."</p>

                    <p>A few years ago I was on a local high kill shelter's website when I saw the most heart-wrenching photo of a little brown female dog who has been at the crowded shelter for a week. Her "due out" date was the next day. She had a big tumor on the side of her face, had a horrible skin infection, was missing hair all over her body, and looked absolutely terrified. </p>

                    <p>"I already have three dogs of my own and I could not possibly get another one" I thought to myself "I can't save them all."</p>

                    <p>So two hours later, I was at the shelter, signing her adoption papers. I named her Emma. </p>

                    <p>I promised to myself that I will buy her some time by fostering her while I nurse her back to health, and find her a good home. Turns out that my Mia (reindeer in photo above) was looking for a dog and ended up adopting her. What a coincidence...</p>

                    <div className="story__quote">
                        It would be really nice if I could just <span className="story__quote-highlight">put this thing back together</span> instead of buying a new one.
                    </div>

                    <p>Anyway, you're probably wondering "Where is this Domino moment?!" Here it comes:</p>

                    <p>See, although Emma listens very well and is a wonderful dog, she has a little problem. She is a destructo-dog. Emma's prey instincts are stronger than your average dog's. A new toy lasts her an average of 30 seconds. She usually goes straight for the limbs, and dismembers the poor thing beyond recognition.  So one day I came home from work to find my bedroom COVERED in shreds of my yoga mat, wooden blinds, a box containing shoes I was meaning to return to the store, and some tennis balls. Emma had no clue who did that. </p>

                    <p> As I was picking up the remains of my finely-shredded, brand new, $40 yoga mat I thought to myself "It would be really nice if I could just put this thing back together instead of buying a new one." And there it hit me! I will make her a toy that she can destroy over and over again, and I can just put back together. After 9 months of planning, drawing, trial and error,Tearribles were born. </p>

                </div>
            </div>
        </Container>
    </div>
)

export default Story